<template>
    <modal ref="modalMedios" titulo="Otros medios de ingreso" no-adicional no-cancelar>
        <div class="row mx-0 my-3 j-center">
            <div class="col-12 px-2 mb-2">
                <div class="h-32px px-3 py-1 cr-pointer br-12 d-middle-center text-white" style="background-color:#176BEF;" @click="setIngreso(1)">
                    <i class="icon-google f-22" />
                    Google
                </div>
            </div>
            <!-- <div class="col-12 px-2 mb-2">
                <div class="h-32px px-3 py-1 cr-pointer br-12 d-middle-center text-white" style="background-color:#29D884;" @click="setIngreso(2)">
                    <i class="icon-email f-18 mr-2" />
                    Correo Electronico
                </div>
            </div> -->
            <div class="col-12 px-2 mb-2">
                <div class="h-32px px-3 py-1 cr-pointer br-12 d-middle-center text-white" style="background-color:#637381;" @click="setIngreso(3)">
                    <i class="icon-lock f-22 mr-2" />
                    Usuario y contraseña
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        toggle(){
            this.$refs.modalMedios.toggle();
        },
        setIngreso(key){
            switch (key){
            case 1:
                this.toggle()
                this.$emit('initGoogle')
                break; 
            case 2:
                this.toggle()
                this.$router.push({name: 'ingreso.correo'})
                break; 
            case 3:
                this.toggle()
                this.$router.push({name: 'ingreso'})
                break;
            
            default:
                break;
            }
        }
    }
}
</script>
<style lang="scss" scoped>

.boton-google{
    background-color:#176BEF;
    transition: all 1s ease-out !important;
}
</style>